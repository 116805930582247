import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PopoutLeft from "../components/popup/left"
import PopoutRight from "../components/popup/right"

const FreeGuidedSetup = () => {
  return (
    <Layout location={"guided-setup"} dark={true} title={"Free Guided Setup"}>
      <div>
        <article
          className="blog-post w-full md:w-10/12 lg:w-7/12 mx-auto px-4 lg:px-2"
          itemScope
          itemType="http://schema.org/Article"
        >
          <header>
            <h2
              className={`  mb-12 article-title text-center`}
              itemProp="headline"
            >
              Free Guided Setup
            </h2>
            <p className="text-xl mb-12 text-center">
              Let us guide you thorugh integrating myradio.click into your
              website.
            </p>
          </header>

          <div className="article-content block mb-32" itemProp="articleBody">
            <PopoutLeft />
            <PopoutRight />
          </div>
        </article>
      </div>
    </Layout>
  )
}

export default FreeGuidedSetup

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => (
  <Seo
    title="Free Guided Setup"
    description="Build your radio station website today!"
  />
)
